export function initEvervault(
  sessionID: string,
  teamID: string,
  appID: string,
) {
  // @ts-expect-error - We don't have Evervault defined as it comes from a CDN and types are not available.
  const evervault = new Evervault(teamID, appID);
  const threeDSecure = evervault.ui.threeDSecure(sessionID);
  threeDSecure.mount();
  return threeDSecure;
}
;
import "/Users/andreja/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";